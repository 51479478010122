
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrendsItem",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
});
